import React from "react";
import { getLanguage } from "data-handler/ducks/language";
import { useSelector } from "react-redux";
import { toMeasureUnitString } from "helpers/measure_unit";
import { MeasureUnit } from "data-handler/ducks/stores";

type MeasureUnitValueProps = {
  measureUnit: MeasureUnit;
  children: number | string;
};
/**
 * Renders `children` as a string representing a number formatted according to the
 * "en-EN" locale and to minimum/maximumFractionDigits.
 *
 * Rationale for not using UI Kit's `Unit`:
 * https://github.com/wfp/ui/issues/307
 */
const MeasureUnitValue: React.ElementType<MeasureUnitValueProps> = ({
  measureUnit,
  children,
}) => {
  return (
    <span>
      {toMeasureUnitString(children, measureUnit, useSelector(getLanguage))}
    </span>
  );
};

export default MeasureUnitValue;
