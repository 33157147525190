import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Search, Button } from "@wfp/ui";
import {
  faChalkboardTeacher,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getCompactSchools,
  fetchSchoolsPaginated,
  clearCurrentSchoolState,
} from "data-handler/ducks/schools";
import {
  getCurrentUser,
  getIsSchoolStaff,
  getLoggedInOffline,
  getRawDataExtractionPermission,
} from "data-handler/ducks/auth";
import Empty from "components/Empty";
import ModalExtended from "components/ModalExtended";
import Pagination from "components/Pagination";
import { RepeaterItem } from "components/Repeater";
import OnlineOffline from "components/OnlineOffline";
import RawDataExtractModal from "./rawDataExtractModal";
import styles from "./styles.module.scss";
import {
  getCanUseOfflineMode,
  getIsAdmin,
  getIsCountryAdmin,
  getIsInternal,
  getIsSchoolGroupAdmin,
} from "helpers/users";

import { getDashboardLinks } from "data-handler/ducks/links";
import { AxiosResponse } from "axios";

type SchoolOverviewDetails = {
  is_current_school_year: boolean;
  days_since_last_sync: number;
  number_of_submitted_reports: number;
};

type CompactSchool = {
  id: number;
  name: string;
  adm_1: { name: string };
  adm_4: { name: string };
  country: string;
  overview_details?: SchoolOverviewDetails;
};

type SchoolSelectProps = {};

/**
 * Modal containing links which redirect to other schools.
 *
 * Only useful for admin users.
 */
const SchoolSelect: React.ElementType<SchoolSelectProps> = () => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [schools, setSchools] = useState<CompactSchool[]>([]);
  const [schoolsCount, setSchoolsCount] = useState(0);

  const [schoolFilter, setSchoolFilter] = useState("");
  const [filterEntry, setFilterEntry] = useState("");
  const [rawDataExtractModalState, setRawDataExtractModalState] = useState(
    false
  );
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const isSchoolStaff = useSelector(getIsSchoolStaff);
  const isOffline = useSelector(getLoggedInOffline);
  const hasRawDataExtractionPermission = useSelector(
    getRawDataExtractionPermission
  );
  const dashboardLinks = useSelector(getDashboardLinks);
  const isInternal = getIsInternal(currentUser?.email);
  const canUseOfflineMode = getCanUseOfflineMode(currentUser);

  const compactSchools = useSelector(getCompactSchools);
  // We do not need this because we watch for paginated school request to complete.
  // and keep track of fetching status manually in variable 'fetching'
  //const isCompactSchoolsFetching = useSelector(getCompactSchoolsFetching);
  //const compactSchoolsCount = useSelector(getCompactSchoolsCount);

  const country = currentUser.country;
  const hasAdminAccess =
    (getIsCountryAdmin(currentUser) && country) || getIsAdmin(currentUser);

  const closeWindow = () => {
    // Remove all location.search except for ?nav
    history.push(history.location.pathname + "?nav=true");
  };

  // if we are already fetching something, do not update filter.
  useEffect(() => {
    if (!fetching) {
      setSchoolFilter(filterEntry);
    }
  }, [fetching, filterEntry]);

  type FetchUserPaginatedResult = {
    results: CompactSchool[];
    count: number;
  };

  useEffect(() => {
    if (canUseOfflineMode) return;
    setFetching(true);
    const offset = (pageNumber - 1) * pageSize;
    const limit = pageSize;
    const emptySchools = [];
    // Prefill the Modal with empty items
    // Keeps the Modal at a standard height.
    // Stops it from jumping around.
    for (let i = 0; i < 20; i++) {
      emptySchools.push({
        id: i,
        name: "",
        adm_1: { name: "" },
        adm_4: { name: "" },
        country: "",
      });
    }
    setSchools(emptySchools);

    fetchSchoolsPaginated(schoolFilter, limit, offset, true).then(
      (res: AxiosResponse<FetchUserPaginatedResult>) => {
        const { results, count } = res.data;
        setSchools(results);
        setSchoolsCount(count);
        setFetching(false);
        return res;
      }
    );
  }, [canUseOfflineMode, pageNumber, pageSize, schoolFilter]);

  useEffect(() => {
    let filteredSchools: CompactSchool[] = [];
    if (!fetching) {
      if (canUseOfflineMode) {
        const lowerSchoolFilter = schoolFilter.toLocaleLowerCase();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        filteredSchools = compactSchools.filter((school: CompactSchool) =>
          school.name.toLocaleLowerCase().includes(lowerSchoolFilter)
        );

        setSchoolsCount(filteredSchools.length);
        const start = (pageNumber - 1) * pageSize;
        const end = start + pageSize;
        filteredSchools = filteredSchools.slice(start, end);
        setSchools(filteredSchools);
      }
    }
  }, [
    fetching,
    pageNumber,
    pageSize,
    schoolFilter,
    compactSchools,
    canUseOfflineMode,
  ]);

  const onChange = ({ page, pageSize }: { page: number; pageSize: number }) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const paginationProps = {
    pageSizes: [10, 20, 30, 40, 50],
    page: pageNumber,
    pageSize: pageSize,
    totalItems: schoolsCount,
    onChange,
  };

  const schoolLinkText = (school: CompactSchool) => {
    if (fetching) {
      return school.country;
    } else {
      return (
        school.country +
        (school.adm_1.name ? " / " + school.adm_1.name : "") +
        (school.adm_4.name ? " / " + school.adm_4.name : "")
      );
    }
  };

  const onChangeFilter = (searchText: string = "") => {
    setFilterEntry(searchText.toLowerCase());
  };

  const dashboardLink = dashboardLinks?.find(
    (item: any) => item.internal === isInternal
  )?.url;
  const header = (
    <div className={styles.header}>
      {schoolsCount} {/* Space between number of schools and title */}
      {schoolsCount !== 1 && (
        <FormattedMessage
          id="schoolSelect.schoolsTitle"
          defaultMessage="Schools"
        />
      )}
      {schoolsCount === 1 && (
        <FormattedMessage
          id="schoolSelect.schoolTitle"
          defaultMessage="School"
        />
      )}
      <div className={styles.buttonContainer}>
        {!isOffline &&
          (hasRawDataExtractionPermission ||
            hasAdminAccess ||
            getIsSchoolGroupAdmin(currentUser)) && (
            <Button
              onClick={() => setRawDataExtractModalState(true)}
              className={styles.dataButton}
            >
              <FormattedMessage
                id="CountryAdmin.RawDataExtractModal"
                defaultMessage="Raw Data Extract"
              />
            </Button>
          )}
        {!isOffline && !isSchoolStaff && (
          <a target="_blank" href={dashboardLink} rel="noopener noreferrer">
            <Button>
              <FormattedMessage
                id="CountryAdmin.dashboardLinkLabel"
                defaultMessage="Dashboard"
              />
            </Button>
          </a>
        )}
        {!isOffline && hasAdminAccess && (
          <>
            <OnlineOffline
              onlineChildren={
                <NavLink to={"/countryAdmin/"}>
                  <Button>
                    <FormattedMessage
                      id="CountryAdmin.countryAdmin"
                      defaultMessage="Country Admin"
                    />
                  </Button>
                </NavLink>
              }
              offlineChildren={
                <Button className={styles.offlineAdminBtn} small kind="accent">
                  <FormattedMessage
                    id="CountryAdmin.adminPortalNotAvailable"
                    defaultMessage="Offline, admin portal not available"
                  />
                </Button>
              }
            />
          </>
        )}

        <NavLink exact to={`/logout`}>
          <Button
            className={styles.offlineAdminBtn}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
          >
            <FormattedMessage
              id="UserNavigation.logoutButtonLabel"
              defaultMessage="Logout"
            />
          </Button>
        </NavLink>
      </div>
    </div>
  );

  const schoolInfoRow = (school: CompactSchool) => {
    return (
      <div className={styles.schoolInfoRow}>
        <div className={styles.schoolInfoRowSchoolName}>{school.name}</div>
        <div style={{ fontSize: "small", textDecoration: "none" }}>
          <FormattedMessage
            id="schoolSelect.daysSinceLastSync"
            defaultMessage="{days_since_last_sync} days since last sync"
            values={{
              days_since_last_sync:
                school.overview_details?.days_since_last_sync,
            }}
          />
        </div>
        <div style={{ fontSize: "small" }}>
          <FormattedMessage
            id="schoolSelect.numberSubmittedReports"
            defaultMessage="{number_of_submitted_reports} reports available for approval"
            values={{
              number_of_submitted_reports:
                school.overview_details?.number_of_submitted_reports,
            }}
          />
        </div>
      </div>
    );
  };

  const onSchoolRowClick = (schoolId: number) => {
    dispatch(clearCurrentSchoolState());
    history.push(`/school/${schoolId}`);
  };

  if (rawDataExtractModalState) {
    return (
      <RawDataExtractModal
        state={rawDataExtractModalState}
        setState={setRawDataExtractModalState}
      />
    );
  } else {
    return (
      <ModalExtended
        open
        onRequestClose={closeWindow}
        modalLabel="School overview"
        modalHeading={header}
        wide
        passiveModal
        selectorPrimaryFocus={false}
        secondaryButtonText={""}
      >
        <div className={styles.schoolSelect}>
          <div className={styles.search}>
            <Search
              kind="large"
              name="input-name"
              labelText="Filter schools"
              onChange={onChangeFilter}
            />
          </div>

          {(!schools || schools.length === 0) && fetching ? (
            <Empty
              title="Loading schools"
              icon={<FontAwesomeIcon icon={faChalkboardTeacher} size="1x" />}
            >
              Wait for a moment
            </Empty>
          ) : !schools || schools.length === 0 ? (
            <Empty
              title={
                <FormattedMessage
                  id="Common.noSchoolFound"
                  defaultMessage="No school found"
                />
              }
              icon={<FontAwesomeIcon icon={faChalkboardTeacher} size="1x" />}
            >
              {currentUser.is_approver ? (
                <FormattedMessage
                  id="Approver.noSchoolsMessage"
                  defaultMessage="Dear {other_names} {last_name} you have no schools assigned, please contact your Country Administrator for assignment of schools"
                  values={{
                    other_names: currentUser.other_names,
                    last_name: currentUser.last_name,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="Common.changeFilter"
                  defaultMessage="Change the filter"
                />
              )}
            </Empty>
          ) : (
            <>
              {schools.map((school) => (
                <RepeaterItem key={school.id}>
                  <NavLink
                    onClick={() => onSchoolRowClick(school.id)}
                    to={`/school/${school.id}`}
                  >
                    {school?.overview_details?.is_current_school_year ? (
                      schoolInfoRow(school)
                    ) : (
                      <div>{school.name}</div>
                    )}
                    <div className={styles.country}>
                      {schoolLinkText(school)}
                    </div>
                  </NavLink>
                </RepeaterItem>
              ))}
            </>
          )}
          <div>
            <Pagination {...paginationProps} />
          </div>
        </div>
      </ModalExtended>
    );
  }
};

const InitializedSchoolSelect: React.ComponentType<SchoolSelectProps> = (
  props
) => {
  return <SchoolSelect {...props} />;
};

export default InitializedSchoolSelect;
