import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import { Modal, Button, InlineLoading } from "@wfp/ui";

import moment from "moment";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Calendar, DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Select from "react-select";

import { selectContainerStyles } from "SCConstants";

import { Filters, SelectOption } from "types";

import styles from "./styles.module.scss";
import "./_content.scss";
import "react-multi-date-picker/styles/layouts/prime.css";

import {
  downloadDataset,
  getDatasetsIsFetching,
} from "data-handler/ducks/datasets";
import { countriesDuck } from "data-handler/ducks/countries";
import { getCurrentUser } from "data-handler/ducks/auth";
import { getIsCountryAdmin, getIsSchoolGroupAdmin } from "helpers/users";

const generateAllDates = () => {
  const months = [];
  const dateEnd = moment();
  const dateStart = moment().subtract({ years: 3 });

  while (dateEnd.diff(dateStart, "months") >= 0) {
    // To display all dates in the "Date Panel" they need to be of DateObject Type.
    const date = new DateObject();
    date.set({
      month: Number(dateStart.format("MM")),
      year: Number(dateStart.format("YYYY")),
    });
    months.push(date);
    dateStart.add(1, "month");
  }
  return months;
};

type RawDataExtractModalProps = {
  state: boolean;
  setState: (state: boolean) => void;
};

const RawDataExtractModal: React.ElementType<RawDataExtractModalProps> = ({
  state,
  setState,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const submitBtnRef = useRef();
  const isFetching = useSelector(getDatasetsIsFetching);
  const currentUser = useSelector(getCurrentUser);
  const countries = useSelector(countriesDuck.getList);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());

  const userHasCountry =
    (getIsCountryAdmin(currentUser) || getIsSchoolGroupAdmin(currentUser)) &&
    currentUser?.country;

  const [dateValue, setDateValue] = useState([] as DateObject[]);

  const methods = useForm({
    defaultValues: {
      country: userHasCountry
        ? {
            value: currentUser.country.id,
            label: currentUser.country.name,
          }
        : null,
    },
  });
  const { control, handleSubmit, errors, register, setValue } = methods;

  useEffect(() => {
    if (countries.length === 0 && !countriesIsFetching) {
      dispatch(countriesDuck.fetchList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateErrorMessage = (
    <label className="wfp--form-required" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.errorMonthLabel"
        defaultMessage="* Please select at least 1 month"
      />
    </label>
  );

  const dateTitle = (
    <label className="wfp--label" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.selectMonthsLabel"
        defaultMessage="Months"
      />
    </label>
  );

  const dateHelperText = (
    <label className="wfp--form__helper-text" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.selectMonthsHelperLabel"
        defaultMessage="Select all months for which you are downloading"
      />
    </label>
  );

  const dataTypeErrorMessage = (
    <label className="wfp--form-required" htmlFor="dataType">
      <FormattedMessage
        id="rawDataExtraction.errorDataTypeLabel"
        defaultMessage="* Please select at least 1 category"
      />
    </label>
  );

  const dataTypeTitle = (
    <label className="wfp--label" htmlFor="raw data select">
      <FormattedMessage
        id="rawDataExtraction.selectDataTypeLabel"
        defaultMessage="Data Categories"
      />
    </label>
  );

  const countryTitle = (
    <label className="wfp--label" htmlFor="country-select">
      <FormattedMessage id="CountryAdmin.country" defaultMessage="Country" />
    </label>
  );

  const countryHelperText = (
    <label className="wfp--form__helper-text" htmlFor="country-select-helper">
      <FormattedMessage
        id="rawDataExtraction.countryHelperText"
        defaultMessage="Select the country for which you are downloading"
      />
    </label>
  );

  const countryErrorMessage = (
    <label className="wfp--form-required" htmlFor="country-select-error">
      <FormattedMessage
        id="rawDataExtraction.errorCountryLabel"
        defaultMessage="* Please select a country"
      />
    </label>
  );

  const allLabel = (
    <FormattedMessage
      id="Common.all"
      defaultMessage="All months from today to {date}"
      values={{ date: moment().subtract({ years: 3 }).format("MM/YYYY") }}
    />
  );

  const dataTypeHelperText = (
    <label className="wfp--form__helper-text" htmlFor="months">
      <FormattedMessage
        id="rawDataExtraction.selectDataTypeHelperLabel"
        defaultMessage="Select all categories for which you are downloading"
      />
    </label>
  );

  const DatePanelHeaderLabel = (
    <FormattedMessage
      id="rawDataExtraction.datePanelHeaderLabel"
      defaultMessage="Selected Months"
    />
  );

  const rawDataOptions = [
    { label: "Stock Movements Monthly", value: "stock_movement" },
  ];

  const countriesOptions = useMemo(() => {
    return countries?.map((country) => ({
      label: country.name,
      value: country.id,
    }));
  }, [countries]);

  const onSubmit = (values: any) => {
    const countryFilter = {
      id: "country_id",
      value: values.country.value as string,
    };

    const dates =
      values.date === "all"
        ? generateAllDates().map((date) => date.format("YYYY-MM"))
        : values.date.split(",");

    const dateFilter = dates.map((date: string) => ({
      id: "months",
      value: date,
    }));

    const filters: Filters = [countryFilter, ...dateFilter];

    const dataset = values.type.value;
    dispatch(downloadDataset(dataset, filters));
  };

  return (
    <Modal
      open={state}
      className="data-download-modal"
      modalHeading={intl.formatMessage({
        id: "rawDataExtraction.title",
        defaultMessage: "Raw Data Extraction",
      })}
      modalFooter={() => (
        <div className="footer-buttons">
          <Button
            className="wfp--btn--secondary"
            onClick={() => setState(false)}
          >
            {
              <FormattedMessage
                id="ModalExtended.cancel"
                defaultMessage="Cancel"
              />
            }
          </Button>
          <Button
            onClick={() => (submitBtnRef.current as any).click()}
            disabled={isFetching}
          >
            {isFetching ? (
              <InlineLoading />
            ) : (
              <FormattedMessage
                id="rawDataExtraction.download"
                defaultMessage="Download"
              />
            )}
          </Button>
        </div>
      )}
      onRequestClose={() => setState(false)}
      selectorPrimaryFocus={true}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="wfp--form wfp--form-long"
        >
          <div className={styles.column}>
            <div>
              <div>
                {dateTitle}
                {dateHelperText}
              </div>
              <Controller
                control={control}
                name="date"
                render={({ onChange, name }: any) => {
                  return (
                    <Calendar
                      value={dateValue}
                      className={
                        errors.hasOwnProperty("date")
                          ? "rmdp-prime rmdp-prime-error"
                          : "rmdp-prime"
                      }
                      multiple
                      onlyMonthPicker
                      onChange={(array: any) => {
                        onChange(
                          array
                            .map((date: any) => date.format("YYYY-MM"))
                            .join(",")
                        );
                      }}
                      /*
                      // @ts-ignore */
                      name={name}
                      shadow={false}
                      styles={{ width: "auto" }}
                      datePanel={{ right: true }}
                      plugins={[
                        <DatePanel
                          sort="date"
                          /*
                          // @ts-ignore */
                          header={DatePanelHeaderLabel}
                        />,
                      ]}
                    >
                      <Button
                        onClick={() => {
                          setValue("date", "all");
                          setDateValue(generateAllDates());
                        }}
                      >
                        {allLabel}
                      </Button>
                    </Calendar>
                  );
                }}
                rules={{ required: true }}
              />
              <div className={styles.errorLabel}>
                {errors.hasOwnProperty("date") && dateErrorMessage}
              </div>
            </div>
            <div className="dataset-selects">
              <div>
                {dataTypeTitle}
                {dataTypeHelperText}
              </div>
              <Controller
                control={control}
                name="type"
                render={({ onChange, value }) => (
                  <Select
                    styles={
                      errors?.hasOwnProperty("type")
                        ? selectContainerStyles.invalid
                        : selectContainerStyles.valid
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={rawDataOptions}
                    onChange={onChange}
                    value={value}
                  />
                )}
                options={rawDataOptions}
                defaultValue={rawDataOptions[0]}
                rules={{ required: true }}
                inputRef={register}
              />
              <div className={styles.errorLabel}>
                {errors.hasOwnProperty("type") && dataTypeErrorMessage}
              </div>
              <div className={styles.countrySelectLabels}>
                {countryTitle}
                {countryHelperText}
              </div>
              <div className="country-select-field">
                <Controller
                  render={(props) => (
                    <Select
                      styles={
                        errors?.hasOwnProperty("country")
                          ? selectContainerStyles.invalid
                          : selectContainerStyles.valid
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isDisabled={userHasCountry}
                      options={countriesOptions}
                      value={props.value}
                      onChange={(value: SelectOption) => props.onChange(value)}
                    />
                  )}
                  id="country"
                  name="country"
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className={styles.errorLabel}>
              {errors.hasOwnProperty("country") && countryErrorMessage}
            </div>
          </div>
          <div className={styles.hidden}>
            <button type="submit" ref={submitBtnRef as any} />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default RawDataExtractModal;
