/**
 * Utilities related to manipulating numbers or string representation of numbers
 *
 */

const DIGIT_EXPRESSION = /^\d$/;

/**
 * Predicate that checks if given character is a digit.
 */
export const isDigit = (character: string): boolean => {
  return Boolean(character) && DIGIT_EXPRESSION.test(character);
};

/**
 * If length of fractions is more then given limit, remove cut the fractions so we would stay inside the limit.
 * limitFractions('12.345', 2) -> '12.34'
 */
export const limitFractions = (
  input: string,
  fractionLimit: number
): string => {
  // Find last non-digit character in the text.
  let fractionIdx: number = input.length - 1;
  while (fractionIdx !== -1 && isDigit(input[fractionIdx])) {
    fractionIdx -= 1;
  }
  const fractions = fractionIdx === -1 ? "" : input.substring(fractionIdx + 1);
  const fractionLength = fractions.length;

  let result = input;
  if (fractionLength > fractionLimit) {
    let cutIdx = input.length - (fractionLength - fractionLimit);
    // If after cutting the fractions last value will be non digit, we will also cut that value.
    // This is so if fractionLimit is zero and we end up a value like '12.', we would also remove the dot at the end.
    // That way it would work with event.target assignments.
    if (!isDigit(input[cutIdx - 1])) {
      cutIdx -= 1;
    }
    result = input.substring(0, cutIdx);
  }
  return result;
};

// This might be a MeasureUnit for Quantity values or Currency for currency values.
export interface DecimalSource {
  decimals: number;
}

/**
 * Format the number using decimals restrictions,
 * It will also format the number for given language
 * (1.23 , decimals 3) => 1.230
 * (1.234, decimals 1) => 1.2
 */
export const toQuantityString = (
  value: string | number,
  decimalSource: DecimalSource,
  language: string
) => {
  const minimumFractionDigits =
    decimalSource?.decimals === undefined ? 3 : decimalSource.decimals;
  const maximumFractionDigits =
    decimalSource?.decimals === undefined ? 3 : decimalSource.decimals;

  // It would probably better if we just got locale value from the browser.
  // But I'll keep this version since this was already in the codebase.
  const locale = language === "en" ? "en-EN" : "fr-FR";
  let numberValue = value;
  if (typeof value === "string") {
    numberValue = parseFloat(value);
  }

  const formattedValue = numberValue.toLocaleString(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
  return formattedValue;
};
