import { all, fork } from "redux-saga/effects";

import {
  compactSchoolsSagaWatcher,
  extendedSchoolsSagaWatcher,
  adminAllSchoolsSagaWatcher,
  adminPendingSchoolsSagaWatcher,
  singleSchoolSagaWatcher,
  moveSchoolToNewProfileSagaWatcher,
  currentAdminSchoolSagaWatcher,
  createSchoolSagaWatcher,
  editSchoolSagaWatcher,
  actionSchoolSagaWatcher,
  setSchoolDistributionReportSagaWatcher,
  distributionReportSchoolSagaWatcher,
  schoolsForCsvTemplateSagaWatcher,
} from "data-handler/ducks/schools";

import {
  commoditySourcesForSchoolSagaWatcher,
  commoditySourcesForUserSagaWatcher,
  allCommoditySourcesSagaWatcher,
} from "data-handler/ducks/schools2";

import { foodSourcesSagaWatcher } from "data-handler/ducks/foodSources";
import { noMealReasonsSagaWatcher } from "data-handler/ducks/noMealReasons";

import commodities from "data-handler/ducks/commodities";
import { regionsDuck } from "data-handler/ducks/regions";
import {
  storesSaga,
  storesPOSTWatcher,
  updateStoreSagaWatcher,
  userStoresSagaWatcher,
} from "data-handler/ducks/stores";
import { loginSagaWatcher } from "data-handler/ducks/auth";
import {
  usersSagaWatcher,
  countryAdminPendingUsersSagaWatcher,
  countryAdminAllUsersSagaWatcher,
  countryAdminActionUserSagaWatcher,
  countryAdminAppoversSagaWatcher,
  countryAdminSchoolGroupAdminSagaWatcher,
  currentUserSagaWatcher,
  editUserSagaWatcher,
} from "data-handler/ducks/users";
import {
  countryAdminSchoolProfilesSagaWatcher,
  countryAdminDetailedSchoolProfileSagaWatcher,
  countryAdminPostDetailedSchoolProfileSagaWatcher,
  countryAdminPostNewSchoolProfileSagaWatcher,
  countryAdminDeleteSchoolProfileSagaWatcher,
  countryAdminSchoolProfilesNonPaginatedSagaWatcher,
} from "data-handler/ducks/schoolProfile";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import deliveryIssuesDuck from "data-handler/ducks/deliveryIssues";
import distributionSitesDuck from "data-handler/ducks/distributionSites";
import { batchNumbersSagaWatcher } from "data-handler/ducks/batchNumbers";
import { downloadDatasetSagaWatcher } from "./ducks/datasets";
import {
  distributionReportsNonPaginatedSagaWatcher,
  partnershipsSagaWatcher,
  locationsSagaWatcher,
  allDistributionReportsSagaWatcher,
  locationsSagaActivityCodesWatcher,
  beneficiaryGroupsSagaWatcher,
  createDistributionReportSagaWatcher,
  editDistributionReportSagaWatcher,
  organizationUnitsSagaWatcher,
  currentDistributionReportSagaWatcher,
} from "data-handler/ducks/distributionReports";
import {
  getCountryAdminRolesSagaWatcher,
  postCountryAdminRolesSagaWatcher,
  getCountryAdminRoleMappingSagaWatcher,
} from "data-handler/ducks/roles";
import reportsDuck from "data-handler/ducks/reports";
import { countriesDuck } from "data-handler/ducks/countries";
import { registrationSaga } from "data-handler/ducks/registration";
import {
  requestCountryAuthorityTypesSagaWatcher,
  requestEducationAuthoritiesSagaWatcher,
} from "data-handler/ducks/authorityLevels";
import {
  requestCountryAdminLevelsSagaWatcher,
  requestSchoolAdminsSagaWatcher,
  requestUpdateCountryAdminLevelsSagaWatcher,
  requestRegistrationSchoolsSagaWatcher,
} from "data-handler/ducks/adminLevels";

import {
  cometAgeGroupsSagaWatcher,
  levelAgeGroupsSagaWatcher,
  updateLevelMappingsSagaWatcher,
} from "./ducks/ageGroups";
import {
  importCsvFileSagaWatcher,
  downloadCsvTemplateSagaWatcher,
  requestSchoolsImportsSagaWatcher,
  requestCurrentSchoolsImportSagaWatcher,
  requestSchoolsImportRecordsSagaWatcher,
} from "data-handler/ducks/schoolsImports";
import { changeLanguageWatcher } from "data-handler/ducks/language";
import { getLinksSagaWatcher } from "data-handler/ducks/links";
import { privacyStatementSagaWatcher } from "data-handler/ducks/statements";
import { termsStatementSagaWatcher } from "data-handler/ducks/statements";
import {
  vendorCategoriesSagaWatcher,
  adminVendorsSagaWatcher,
  performVendorActionSagaWatcher,
  allApprovedVendorsSagaWatcher,
} from "./ducks/vendors";
import { purchaseOtherCostsSagaWatcher } from "./ducks/purchaseOtherCosts";

export default function* rootSaga() {
  yield all([
    fork(actionSchoolSagaWatcher),
    fork(adminAllSchoolsSagaWatcher),
    fork(adminPendingSchoolsSagaWatcher),
    fork(allDistributionReportsSagaWatcher),
    fork(compactSchoolsSagaWatcher),
    fork(extendedSchoolsSagaWatcher),
    fork(batchNumbersSagaWatcher),
    fork(beneficiaryGroupsSagaWatcher),
    fork(changeLanguageWatcher),
    fork(cometAgeGroupsSagaWatcher),
    fork(commodities._detailWatcher),
    fork(commodities._listWatcher),
    fork(countriesDuck._detailWatcher),
    fork(countriesDuck._listWatcher),
    fork(countryAdminActionUserSagaWatcher),
    fork(countryAdminAllUsersSagaWatcher),
    fork(countryAdminAppoversSagaWatcher),
    fork(countryAdminSchoolGroupAdminSagaWatcher),
    fork(countryAdminDeleteSchoolProfileSagaWatcher),
    fork(countryAdminDetailedSchoolProfileSagaWatcher),
    fork(countryAdminPendingUsersSagaWatcher),
    fork(countryAdminPostDetailedSchoolProfileSagaWatcher),
    fork(countryAdminPostNewSchoolProfileSagaWatcher),
    fork(countryAdminSchoolProfilesNonPaginatedSagaWatcher),
    fork(countryAdminSchoolProfilesSagaWatcher),
    fork(createDistributionReportSagaWatcher),
    fork(createSchoolSagaWatcher),
    fork(currentAdminSchoolSagaWatcher),
    fork(currentDistributionReportSagaWatcher),
    fork(currentUserSagaWatcher),
    fork(deliveryIssuesDuck._detailWatcher),
    fork(deliveryIssuesDuck._listWatcher),
    fork(distributionReportSchoolSagaWatcher),
    fork(distributionReportsNonPaginatedSagaWatcher),
    fork(distributionReportsNonPaginatedSagaWatcher),
    fork(distributionSitesDuck._detailWatcher),
    fork(distributionSitesDuck._listWatcher),
    fork(downloadCsvTemplateSagaWatcher),
    fork(editDistributionReportSagaWatcher),
    fork(editSchoolSagaWatcher),
    fork(editUserSagaWatcher),
    fork(getCountryAdminRoleMappingSagaWatcher),
    fork(getCountryAdminRolesSagaWatcher),
    fork(getLinksSagaWatcher),
    fork(importCsvFileSagaWatcher),
    fork(incidentCausesDuck._detailWatcher),
    fork(incidentCausesDuck._listWatcher),
    fork(levelAgeGroupsSagaWatcher),
    fork(locationsSagaActivityCodesWatcher),
    fork(locationsSagaWatcher),
    fork(locationsSagaWatcher),
    fork(loginSagaWatcher),
    fork(moveSchoolToNewProfileSagaWatcher),
    fork(organizationUnitsSagaWatcher),
    fork(partnershipsSagaWatcher),
    fork(partnershipsSagaWatcher),
    fork(postCountryAdminRolesSagaWatcher),
    fork(regionsDuck._detailWatcher),
    fork(regionsDuck._listWatcher),
    fork(registrationSaga),
    fork(reportsDuck._detailWatcher),
    fork(reportsDuck._listWatcher),
    fork(requestCountryAdminLevelsSagaWatcher),
    fork(requestCountryAuthorityTypesSagaWatcher),
    fork(requestCurrentSchoolsImportSagaWatcher),
    fork(requestEducationAuthoritiesSagaWatcher),
    fork(requestRegistrationSchoolsSagaWatcher),
    fork(requestSchoolAdminsSagaWatcher),
    fork(requestSchoolsImportRecordsSagaWatcher),
    fork(requestSchoolsImportsSagaWatcher),
    fork(requestUpdateCountryAdminLevelsSagaWatcher),
    fork(schoolsForCsvTemplateSagaWatcher),
    fork(setSchoolDistributionReportSagaWatcher),
    fork(singleSchoolSagaWatcher),
    fork(storesPOSTWatcher),
    fork(updateStoreSagaWatcher),
    fork(userStoresSagaWatcher),
    fork(storesSaga),
    fork(updateLevelMappingsSagaWatcher),
    fork(usersSagaWatcher),
    fork(privacyStatementSagaWatcher),
    fork(termsStatementSagaWatcher),
    fork(adminVendorsSagaWatcher),
    fork(vendorCategoriesSagaWatcher),
    fork(performVendorActionSagaWatcher),
    fork(purchaseOtherCostsSagaWatcher),
    fork(allApprovedVendorsSagaWatcher),
    fork(commoditySourcesForSchoolSagaWatcher),
    fork(commoditySourcesForUserSagaWatcher),
    fork(allCommoditySourcesSagaWatcher),
    fork(foodSourcesSagaWatcher),
    fork(noMealReasonsSagaWatcher),
    fork(downloadDatasetSagaWatcher),
  ]);
}
