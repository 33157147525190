import { MeasureUnit } from "data-handler/ducks/stores";
import { toQuantityString } from "./numbers";

/**
 * Returns formatted string of given number with given measureUnit
 */
export const toMeasureUnitString = (
  value: string | number,
  measureUnit: MeasureUnit,
  language: string
): string => {
  const formattedValue = toQuantityString(value, measureUnit, language);
  return `${formattedValue} ${measureUnit.symbol}`;
};
