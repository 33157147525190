import React from "react";
import { getLanguage } from "data-handler/ducks/language";
import { useSelector } from "react-redux";
import { toCurrencyString } from "helpers/currency";
import { Currency } from "data-handler/ducks/countryProfile";

type CurrencyValueProps = {
  currency: Currency;
  children: number | string;
};

/**
 * Renders `children` as a string representing a number formatted according to the
 * "en-EN" locale and to minimum/maximumFractionDigits.
 *
 * Rationale for not using UI Kit's `Unit`:
 * https://github.com/wfp/ui/issues/307
 */
const CurrencyValue: React.ElementType<CurrencyValueProps> = ({
  currency,
  children,
}) => {
  return (
    <span>
      {toCurrencyString(children, currency, useSelector(getLanguage))}
    </span>
  );
};

export default CurrencyValue;
