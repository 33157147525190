import { call, put, takeLatest } from "redux-saga/effects";
import axios, { AxiosError, AxiosResponse } from "axios";

import { errorToast } from "data-handler/ducks/toast";
import { toastFormattedMessages } from "SCConstants";
import { downloadBlob } from "data-handler/ducks/utils";
import { RootState } from "data-handler/rootReducer";

import { Filters } from "types";

const DOWNLOAD_DATASET_REQUEST =
  "schoolconnect/datasets/DOWNLOAD_DATASET_REQUEST";
const DOWNLOAD_DATASET_SUCCESS =
  "schoolconnect/datasets/DOWNLOAD_DATASET_SUCCESS";
const DOWNLOAD_DATASET_FAILURE =
  "schoolconnect/datasets/DOWNLOAD_DATASET_FAILURE";

type State = {
  fetching: boolean;
  error: any;
  errorResponse: any;
};

type Dataset = "stock_movement";

type DownloadDatasetRequestAction = {
  type: typeof DOWNLOAD_DATASET_REQUEST;
  dataset: Dataset;
  filters: Filters;
};

type DownloadDatasetSuccessAction = {
  type: typeof DOWNLOAD_DATASET_SUCCESS;
};

type DownloadDatasetFailureAction = {
  type: typeof DOWNLOAD_DATASET_FAILURE;
  error: AxiosError;
};

type Action =
  | DownloadDatasetRequestAction
  | DownloadDatasetSuccessAction
  | DownloadDatasetFailureAction;

const initialState: State = {
  fetching: false,
  error: null,
  errorResponse: null,
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case DOWNLOAD_DATASET_REQUEST:
      return { ...state, fetching: true, error: null };
    case DOWNLOAD_DATASET_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case DOWNLOAD_DATASET_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

// Action
export const downloadDataset = (dataset: Dataset, filters: Filters) => ({
  type: DOWNLOAD_DATASET_REQUEST,
  dataset,
  filters,
});

// Saga
export function* downloadDatasetSagaWatcher() {
  yield takeLatest(DOWNLOAD_DATASET_REQUEST, downloadDatasetSagaWorker);
}

function fetchDataset(action: DownloadDatasetRequestAction) {
  const { dataset, filters } = action;

  const url =
    `${process.env.REACT_APP_API_URL}/datasets/${dataset}/?` +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");

  return axios({
    method: "GET",
    url: url,
    responseType: "blob",
  });
}

function* downloadDatasetSagaWorker(action: DownloadDatasetRequestAction) {
  try {
    const { dataset } = action;
    const response: AxiosResponse = yield call(fetchDataset, action);
    const { data: blob } = response;
    downloadBlob(blob, `${dataset.toUpperCase()}.csv`);
    yield put({ type: DOWNLOAD_DATASET_SUCCESS });
  } catch (error) {
    yield put({ type: DOWNLOAD_DATASET_FAILURE, error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          ?.label
      )
    );
  }
}

export const getDatasetsIsFetching = (state: RootState) =>
  state.datasets.fetching;
