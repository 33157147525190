import { Currency } from "data-handler/ducks/countryProfile";
import { toQuantityString } from "./numbers";

/**
 * Returns formatted string of given number with given Currency
 */
export const toCurrencyString = (
  value: string | number,
  currency: Currency,
  language: string
): string => {
  const formattedValue = toQuantityString(value, currency, language);
  return `${formattedValue} (${currency.code})`;
};
